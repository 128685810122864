import React from "react"
import Section from "../../Elements/Section"

const ProofOfPaymentPersonalDetails = ({ orderDetails }) => {
  if (!!orderDetails?.firstName)
    return (
      <Section title="Delivery Details">
        <p className="is-size-6 mt-1">
          <span className="help has-text-grey is-uppercase">First Name</span>
          <span className="has-text-weight-bold">
            {orderDetails?.firstName}
          </span>
        </p>
        <p className="is-size-6 mt-1">
          <span className="help has-text-grey is-uppercase">Last Name</span>
          <span className="has-text-weight-bold">{orderDetails?.lastName}</span>
        </p>
        <p className="is-size-6 mt-1">
          <span className="help has-text-grey is-uppercase">
            Delivery Address
          </span>
          <span className="help">
            Please check your delivery address. Your delivery address cannot be
            changed once we confirm your proof of payment.
          </span>
          <span className="has-text-weight-bold">
            {orderDetails?.streetAddress}, {orderDetails?.city},{" "}
            {orderDetails?.province}
          </span>
        </p>
        <p className="is-size-6 mt-1">
          <span className="help has-text-grey is-uppercase">
            Delivery Notes
          </span>
          <span className="has-text-weight-bold">
            {orderDetails?.deliveryNotes || "N/A"}
          </span>
        </p>
      </Section>
    )
  return null
}

export default ProofOfPaymentPersonalDetails
