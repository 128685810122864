import { useStaticQuery, graphql } from "gatsby"

const useProofOfPaymentIcon = () => {
  const data = useStaticQuery(graphql`
    {
      document: file(relativePath: { eq: "icons/upload__document.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      prescription: file(
        relativePath: { eq: "icons/upload__prescription.png" }
      ) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      email: file(relativePath: { eq: "icons/email.png" }) {
        childImageSharp {
          fluid(maxWidth: 512) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return data
}

export default useProofOfPaymentIcon
